import { Button } from 'components/Elements';
import { Form, InputField, PhoneInputField } from 'components/Form';

import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';
import { useLoginWithPhoneNumber } from '../api/loginWithPhoneNumber';

export const LoginForm = ({}) => {
  const [phoneNumber, setPhoneNumber] = useState('');

  const loginMutation = useLoginWithPhoneNumber();

  const navigate = useNavigate();

  return (
    <div>
      <Form
        onSubmit={async (values) => {
          const result = await loginMutation.mutateAsync({
            data: phoneNumber,
          });
          localStorage.setItem('phoneNumber', phoneNumber);
          if (result) {
            navigate('/auth/verify-otp');
          }
        }}
      >
        {({ register, formState }) => (
          <>
            <PhoneInputField
              label="Enter your mobile number"
              error={formState.errors['phoneNumber']}
              registration={register('phoneNumber')}
              onChange={setPhoneNumber}
            />
            <div>
              <Button isLoading={loginMutation.isLoading} type="submit" className="w-full">
                Continue
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

LoginForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};
