import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

export const VerificationInputField = ({ length, onChange, value }) => {
  const inputs = useRef([]);

  const handleChange = (e, index) => {
    const newValue = e.target.value.replace(/[^0-9]/g, '');
    if (newValue.length > 1) return;

    const newOtp = [...value];
    newOtp[index] = newValue;
    onChange(newOtp.join(''));

    if (newValue && index < length - 1) {
      inputs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !value[index] && index > 0) {
      inputs.current[index - 1].focus();
    }
  };

  return (
    <div className="flex space-x-2">
      {Array.from({ length }).map((_, index) => (
        <input
          key={index}
          type="text"
          maxLength="1"
          value={value[index] || ''}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          ref={(el) => (inputs.current[index] = el)}
          className={clsx(
            'w-12 h-12 border border-gray-300 rounded text-center text-lg',
            'focus:outline-none focus:ring-2 focus:ring-blue-500'
          )}
        />
      ))}
    </div>
  );
};

VerificationInputField.propTypes = {
  length: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
