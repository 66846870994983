import { useNavigate } from 'react-router-dom';

import { Layout } from '../components/Layout';
import { VerifyOTPForm } from '../components/VerifyOTPForm';

export const VerifyOTP = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <div className="mt-4">
        <VerifyOTPForm onSuccess={() => navigate('/app')} />
      </div>
    </Layout>
  );
};
