import clsx from 'clsx';
import { useState } from 'react'; // Import useState hook

import { FieldWrapper } from './FieldWrapper';
import { Eye, EyeOff } from 'react-feather';

export const InputField = (props) => {
  const {
    id,
    type = 'text',
    label,
    className,
    onChange,
    maxLength,
    minLength,
    caption,
    registration,
    placeholder,
    passwordField,
    error,
    required,
  } = props;

  // State to manage password visibility and input value for formatting numbers
  const [showPassword, setShowPassword] = useState(false);
  const [value, setValue] = useState('');

  // Handle change to format number inputs with commas
  const handleChange = (e) => {
    if (type === 'number') {
      const formattedValue = e.target.value
        .replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      setValue(formattedValue);
      if (onChange)
        onChange({ ...e, target: { ...e.target, value: formattedValue.replace(/,/g, '') } });
    } else {
      setValue(e.target.value);
      if (onChange) onChange(e);
    }
  };

  return (
    <FieldWrapper label={label} error={error}>
      <div className="relative">
        <input
          id={id}
          type={passwordField ? (showPassword ? 'text' : 'password') : type}
          maxLength={maxLength}
          minLength={minLength}
          className={clsx(
            'appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm',
            className
          )}
          required={required}
          placeholder={placeholder}
          value={type === 'number' ? value : undefined} // Only control value for number type to format
          onChange={handleChange}
          {...registration}
        />
        {passwordField && (
          <button
            type="button"
            className="absolute top-1/2 right-3 transform -translate-y-1/2 text-sm text-gray-500"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <EyeOff className="h-4 w-4" /> : <Eye className="h-4 w-4" />}
          </button>
        )}
      </div>
      <div className="my-1 text-xs flex justify-end">{caption}</div>
    </FieldWrapper>
  );
};
