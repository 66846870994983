import React, { useState } from 'react';
import { Button } from 'components/Elements';
import { Form, VerificationInputField } from 'components/Form';
import { useAuth } from 'lib/auth';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

export const VerifyOTPForm = ({ onSuccess }) => {
  const { login, isLoggingIn } = useAuth();
  const [otp, setOtp] = useState('');

  const navigate = useNavigate();

  return (
    <div className="p-4">
      <Form
        onSubmit={async () => {
          await login({ otp: otp, phoneNumber: localStorage.getItem('phoneNumber') });
          onSuccess();
        }}
      >
        {({}) => (
          <>
            <div className="">
              <h2 className="text-2xl font-bold mb-4">Verify OTP</h2>
              <VerificationInputField length={4} value={otp} onChange={setOtp} />
              <div className="mt-4">
                <Button isLoading={isLoggingIn} type="submit" className="w-full">
                  Verify
                </Button>
              </div>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

VerifyOTPForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};
