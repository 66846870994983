import React from 'react';
import clsx from 'clsx';
import { FieldWrapper } from './FieldWrapper';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

export const PhoneInputField = (props) => {
  const { className, caption, label, registration, error, onChange, value } = props;

  return (
    <FieldWrapper label={label} error={error}>
      {/* <div
        className={clsx(
          'flex flex-row items-center space-x-2 appearance-none block w-full px-8 py-4 bg-white rounded-md border border-gray-300 sm:text-lg',
          className
        )}
      >
      </div> */}
      <PhoneInput
        defaultCountry="ug"
        inputClassName="w-full"
        value={value}
        onChange={onChange}
        className="block w-full bg-white border-0 placeholder-gray-400 focus:outline-none focus:ring-0 w-full"
        {...registration}
      />
      <div className="my-1 text-xs flex justify-end">{caption}</div>
    </FieldWrapper>
  );
};
