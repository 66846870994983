import { axios } from 'lib/axios';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';

export const loginWithPhoneNumber = ({ data }) => {
  return axios.post(`/auth/login`, data);
};

export const useLoginWithPhoneNumber = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (applying) => {},
    onError: (_, __, context) => {},
    onSuccess: (data) => {},
    ...config,
    mutationFn: loginWithPhoneNumber,
  });
};
