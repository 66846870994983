import { Button } from 'components/Elements';
import { Head } from 'components/Head';
import { WebLayout } from 'components/Layout';
import { useAuth } from 'lib/auth';
import { useNavigate } from 'react-router';
import LogoImg from 'assets/logo.svg';
import { Check } from 'react-feather';
import { useEffect } from 'react';

export const Landing = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const handleStart = () => {
      if (user) {
        navigate('/app');
      }
    };
    handleStart();
  }, []);

  const handleStart = () => {
    if (user) {
      navigate('/app');
    } else {
      navigate('/auth/login');
    }
  };

  return (
    <>
      <Head title="Home" description="Friendly neighborhood chat" />
      <div className="flex flex-col justify-center items-center">
        <div className="md:w-1/4 my-32 flex flex-col items-center justify-center">
          <div className="mt-4">
            <img src={LogoImg} alt="JustKnow" className="h-20 w-auto" />
          </div>
          <div className="">
            <div className="mb-8">Your neighbourhood chat</div>
            <Button onClick={handleStart} className="w-full">
              Get Started
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
