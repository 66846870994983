import { Button, Link } from 'components/Elements';
import { Head } from 'components/Head';
import PropTypes from 'prop-types';
import CampaignCreatorsBg from 'assets/campaign-creators.jpg';
import OverLay from 'assets/layout/overlay.svg';
import LendMoneyImg from 'assets/lend_money.png';
import MyPortfolioImg from 'assets/my_portfolio.png';
import BorrowMoneyImg from 'assets/borrow_money.png';
import logo from 'assets/logo.svg';

export const Layout = ({ children, title }) => {
  return (
    <>
      <Head title={title} />
      <div className="">
        <div className="flex justify-center py-4 sm:px-6 lg:px-8">
          <div className="mt-12 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="py-8 px-4 mt-12 sm:px-10 md:mr-12">
              <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <div className="flex justify-center mb-8">
                  <Link className="flex items-center text-white" to="/">
                    <img className="h-20 w-auto" src={logo} alt="finablr" />
                  </Link>
                </div>

                <h2 className="mt-3 text-center text-xl font-extrabold text-gray-900 dark:text-white">
                  {title}
                </h2>
              </div>
              <div className="my-3">{children}</div>
              <div className="mt-4 text-xs uppercase text-center text-gray-500">
                NorthUp Limited © 2024 All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};
